import axios from "axios";
import qs from "qs";

// 银行卡认证信息
export function getDetailLocation(lng, lat) {
  if (!lng || !lat) throw "缺少经纬度信息";
  const param = {
    postStr: "{'lon':" + lng + ",'lat':" + lat + ",'ver':2}",
    tk: "e2b86beb3d0e85f0accd5c1baab0b7a2",
    type: "geocode"
  };

  return axios({
    method: "get",
    url: `https://api.tianditu.gov.cn/geocoder?${qs.stringify(param)}`,
    params: param,
    data: {},
    headers: {
      "Content-Type":
        "application/x-www-form-urlencoded;charset=UTF-8;multipart/form-data"
    }
  });
}
